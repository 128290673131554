/*================================================================================================================
*                                                  Rights
* ==============================================================================================================*/

// users
export const GET_USER_KEY = 'user';
export const GET_USERS_KEY = 'users';
export const UPSERT_USERS_KEY = 'upsertUser';
export const LINK_USER_GOOGLE_ACCOUNT = 'linkUserGoogleAccount';
export const UNLINK_USER_EMAIL = 'unlinkUserEmail';
export const UPDATE_EMPLOYEE_CREDENTIALS = 'updateEmployeeCredentials';
export const RESET_EMPLOYEE_CREDENTIALS = 'resetEmployeeCredentials';
export const LOGOUT_USERS_KEY = 'logoutUsers';
export const LOGOUT_ALL_USERS_KEY = 'logoutAllUsers';
export const DELETE_USER_KEY = 'deleteUser';

// rights
export const GET_RIGHTS_KEY = 'rights';
export const UPSERT_RIGHT_KEY = 'upsertRight';
export const DELETE_RIGHT_KEY = 'deleteRight';

// roles
export const GET_ROLES_KEY = 'roles';
export const UPSERT_ROLE_KEY = 'upsertRole';
export const DELETE_ROLE_KEY = 'deleteRole';


/*================================================================================================================
*                                                  Master Data
* ==============================================================================================================*/

// Division
export const GET_DIVISIONS_KEY = 'divisions';
export const UPSERT_DIVISION_KEY = 'upsertDivision';
export const DELETE_DIVISION_KEY = 'deleteDivision';

// Subdivision
export const GET_SUBDIVISIONS_KEY = 'subdivisions';
export const UPSERT_SUBDIVISION_KEY = 'upsertSubdivision';
export const DELETE_SUBDIVISION_KEY = 'deleteSubdivision';

// Department
export const GET_DEPARTMENTS_KEY = 'departments';
export const UPSERT_DEPARTMENT_KEY = 'upsertDepartment';
export const DELETE_DEPARTMENT_KEY = 'deleteDepartment';

// Employee
export const GET_EMPLOYEES_KEY = 'employees';
export const UPSERT_EMPLOYEE_KEY = 'upsertEmployee';
export const DELETE_EMPLOYEE_KEY = 'deleteEmployee';
export const EMPLOYEE_AUTH_FULL_SYNC = 'employeeAuthFullSync';

// Employee Card
export const GET_EMPLOYEE_CARDS_KEY = 'employeeCards';
export const UPSERT_EMPLOYEE_CARD_KEY = 'upsertEmployeeCard';
export const DELETE_EMPLOYEE_CARD_KEY = 'deleteEmployeeCard';
export const EMPLOYEE_CARD_AUTH_FULL_SYNC = 'employeeCardAuthFullSync';

// Location
export const GET_LOCATIONS_KEY = 'locations';
export const UPSERT_LOCATION_KEY = 'upsertLocation';
export const DELETE_LOCATION_KEY = 'deleteLocation';

// Department Location Bridge
export const GET_DEPARTMENT_LOCATION_BRIDGES_KEY = 'departmentLocationBridges';
export const UPSERT_DEPARTMENT_LOCATION_BRIDGE_KEY = 'upsertDepartmentLocationBridge';
export const UPSERT_BULK_DEPARTMENT_LOCATION_BRIDGE_KEY = 'upsertBulkDepartmentLocationBridge';
export const DELETE_DEPARTMENT_LOCATION_BRIDGE_KEY = 'deleteDepartmentLocationBridge';

/*================================================================================================================
*                                             Dev Tools Master Data
* ==============================================================================================================*/

// Contact Us
export const GET_CONTACTS_KEY = 'contacts';
export const UPSERT_CONTACT_KEY = 'upsertContact';
export const DELETE_CONTACT_KEY = 'deleteContact';

// News
export const GET_NEWS_KEY = 'news';
export const UPSERT_NEWS_KEY = 'upsertNews';
export const DELETE_NEWS_KEY = 'deleteNews';

// FrequentlyAskedQuestion
export const GET_FREQUENTLY_ASKED_QUESTIONS_KEY = 'frequentlyAskedQuestion';
export const UPSERT_FREQUENTLY_ASKED_QUESTION_KEY = 'upsertfrequentlyAskedQuestion';
export const DELETE_FREQUENTLY_ASKED_QUESTION_KEY = 'deletefrequentlyAskedQuestion';

// Logging
export const GET_LOGS_KEY = 'logs';

export const GET_FARM_SYNC_STATUS = 'syncFarmStatus';
export const SET_FARM_SYNC_STATUS = 'setFarmSyncStatus';
export const TRIGGER_FARM_FULL_SYNC = 'fullFarmSync';

export const GET_AUTH_SYNC_STATUS = 'syncAuthStatus';
export const SET_AUTH_SYNC_STATUS = 'setAuthSyncStatus';
export const TRIGGER_AUTH_FULL_SYNC = 'fullAuthSync';


/*================================================================================================================
*                                                    Fresh Service
* ==============================================================================================================*/

export const UPSERT_FRESH_SERVICE_TICKET = 'upsertFreshServiceTicket';

/*================================================================================================================
*                                                  Transactional
* ==============================================================================================================*/

// Drop Off Allocation
export const GET_DROP_OFF_ALLOCATIONS_KEY = 'dropOffAllocations';
export const UPSERT_DROP_OFF_ALLOCATION_KEY = 'upsertDropOffAllocation';
export const DELETE_DROP_OFF_ALLOCATION_KEY = 'deleteDropOffAllocation';
